<template>
	<div class="header">
		<div class="top">
			<div class="top_bar">
				<router-link class="logo" to="/">
					<span v-if="site.logoType==1">{{site.wbTitle}}</span>
					<img v-else-if="site.logo && site.logo.length>0" :src="site.logo" alt="" />
					<img v-else src="/images/logo.png" alt="" />
				</router-link>
				<div v-if="site.headerList && site.headerList.length>0" class="menu">
					<div class="menu_pc">
						<div v-for="(item, index) in site.headerList" :key="index" class="menu_item">
							<span @click="$router.push({path: item.linkUrl})" :class="'menu_link'+(currentPath==item.linkUrl?' current':'')">{{item.linkTitle}}</span>
							<div v-if="item.childList && item.childList.length>0" class="menu_children">
								<span v-for="(childItem, childIndex) in item.childList" :key="childIndex" @click="$router.push({path: childItem.linkUrl})" :class="'menu_child_link'+(currentPath==childItem.linkUrl?' current':'')">{{childItem.linkTitle}}</span>
							</div>
						</div>
					</div>
					<div :class="'menu_mob'+(showMenu?' menu_mob_show':'')">
						<div class="menu_icon" @click="showMenu=!showMenu">
							<span></span>
						</div>
						<div class="menu_list">
							<div v-for="(item, index) in site.headerList" :key="index" class="menu_item">
								<span @click="$router.push({path: item.linkUrl});showMenu=false;" :class="'menu_link'+(currentPath==item.linkUrl?' current':'')">{{item.linkTitle}}</span>
								<div v-if="item.childList && item.childList.length>0" class="menu_children">
									<span v-for="(childItem, childIndex) in item.childList" :key="childIndex" @click="$router.push({path: childItem.linkUrl});showMenu=false;" :class="'menu_child_link'+(currentPath==childItem.linkUrl?' current':'')">{{childItem.linkTitle}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="top_bg"></div>
	</div>
</template>
<script>
	export default {
		name: 'Header',
		props: ['site'],
		data() {
			return {
				currentPath: this.$route.path,
				showMenu: false
			}
		},
		watch: {
			$route(to) {
				this.currentPath = to.path
			}
		}
	}
</script>
<style lang="scss" scoped>
	$top-height: 67px;
	$mob-top-height: 40px;
	.top {
		background: #fff;
		position: absolute;
		z-index: 1000;
		top: 0;
		left: 0;
		right: 0;
		@media (max-width: $mob-screen) {
			position: fixed;
		}
		.top_bar {
			@include body_block;
			height: $top-height;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			align-items: center;
			@media (max-width: $mob-screen) {
				height: $mob-top-height;
			}
			.logo {
				span {
					font-size: 30px;
					font-weight: 600;
					color: #000;
					@media (max-width: $mob-screen) {
						font-size: 20px;
					}
				}
				img {
					display: block;
					height: 36px;
					@media (max-width: $mob-screen) {
						height: 24px;
					}
				}
			}
			.menu {
				.menu_pc {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					.menu_item {
						width: 100px;
						position: relative;
						@media (max-width: 800px) {
							width: 80px;
						}
						.menu_link {
							display: block;
							line-height: 30px;
							text-align: center;
							color: #000;
							font-size: 16px;
							font-weight: bold;
							cursor: pointer;
						}
						.current {
							color: $--color-primary;
						}
						.menu_children {
							display: none;
						}
						&:hover {
							.menu_children {
								display: block;
								position: absolute;
								top: 30px;
								left: 0;
								right: 0;
								border: 1px solid #232325;
								background: #fff;
								.menu_child_link {
									display: block;
									line-height: 30px;
									text-align: center;
									font-size: 16px;
									color: #222;
									padding: 5px 0;
									cursor: pointer;
								}
								.current {
									color: $--color-primary;
								}
							}
						}
					}
					@media (max-width: $mob-screen) {
						display: none;
					}
				}
				.menu_mob {
					display: none;
					position: relative;
					.menu_icon {
						width: 24px;
						height: 24px;
						position: relative;
						cursor: pointer;
						&:before,
						&:after,
						span {
							display: block;
							content: '';
							position: absolute;
							left: 0;
							right: 0;
							height: 4px;
							background: #000;
						}
						&:before {
							top: 2px;
						}
						&:after {
							bottom: 2px;
						}
						span {
							top: 10px;
						}
					}
					.menu_list {
						display: none;
					}
					@media (max-width: $mob-screen) {
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
				.menu_mob_show {
					.menu_icon {
						span {
							opacity: 0;
						}
						&:before {
							left: -2px;
							right: -2px;
							top: 10px;
							transform: rotate(45deg);
						}
						&:after {
							left: -2px;
							right: -2px;
							bottom: 10px;
							transform: rotate(-45deg);
						}
					}
					.menu_list {
						position: fixed;
						display: block;
						top: $mob-top-height;
						left: 0;
						right: 0;
						bottom: 0;
						background: #fff;
						padding: 16px;
						line-height: 30px;
						font-size: 16px;
						.menu_item {
							.menu_link {
								color: #222;
								display: block;
								cursor: pointer;
							}
							.current {
								color: $--color-primary;
							}
							.menu_children {
								padding-left: 30px;
								.menu_child_link {
									color: #222;
									display: block;
									cursor: pointer;
								}
								.current {
									color: $--color-primary;
								}
							}
						}
					}
				}
			}
		}
	}
	
	.top_bg {
		height: $top-height;
		@media (max-width: $mob-screen) {
			height: $mob-top-height;
		}
	}
</style>